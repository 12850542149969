import * as goog from '../closure/goog/goog.js';
goog.declareModuleId('Blockly.BoardLibrary');

export interface Board {
  type : string,
  portCount: number,
  operatingVoltage : number,
  ports: string[][],
  availPorts:string[],
  I2C_count: number,
  SPI_count: number,
  Serial_count: number,
  built_in_led: string,
  compilerFlag: string,
  serialSpeed: string[]
}

export class BoardLibrary {
  static Boards :Board[] = [];

  selectedBoard : string = 'none';

  static getBoard(bType:string):Board|undefined{
    for (const eboard of this.Boards)
    {
      if (eboard.type == bType)
        return eboard;
    }
    return undefined;
  }

  static getPortFunction(boardType:string, portName:string):string[]|undefined{
    let board = this.getBoard(boardType);
    for (const ePort of board!.ports)
    {
      if (portName == ePort[0])
        return ePort;
    }
    return undefined;
  }

  static getDigitalPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (const ePort of tBoard!.ports)
    {
      if (ePort[1] == 'DIO'
          || ePort[2] == 'DIO'
          || ePort[3] == 'DIO')
        ports.push(ePort[0]);
    }

    return ports;
  }

  static getAnalogPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (const ePort of tBoard!.ports)
    {
      if (ePort[1] == 'AI'
          || ePort[2] == 'AI'
          || ePort[3] == 'AI')
        ports.push(ePort[0]);
    }

    return ports;
  }

  static getPWMPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (const ePort of tBoard!.ports)
    {
      if (ePort[1] == 'PWM'
          || ePort[2] == 'PWM'
          || ePort[3] == 'PWM')
        ports.push(ePort[0]);
    }

    return ports;
  }

  static getSerialRXPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (let portIndex = 0; portIndex < tBoard!.Serial_count; portIndex++)
    {
      for (const ePort of tBoard!.ports)
      {
        if (ePort[1] == ('RX'+portIndex)
            || ePort[2] == ('RX'+portIndex)
            || ePort[3] == ('RX'+portIndex))
          ports.push(ePort[0]);
      }
    }

    return ports;
  }

  static getSerialTXPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (let portIndex = 0; portIndex < tBoard!.Serial_count; portIndex++)
    {
      for (const ePort of tBoard!.ports)
      {
        if (ePort[1] == ('TX'+portIndex)
            || ePort[2] == ('TX'+portIndex)
            || ePort[3] == ('TX'+portIndex))
          ports.push(ePort[0]);
      }
    }

    return ports;
  }

  static getI2CSDAPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (let portIndex = 0; portIndex < tBoard!.I2C_count; portIndex++)
    {
      for (const ePort of tBoard!.ports)
      {
        if (ePort[1] == ('SDA'+portIndex)
            || ePort[2] == ('SDA'+portIndex)
            || ePort[3] == ('SDA'+portIndex))
          ports.push(ePort[0]);
      }
    }

    return ports;
  }

  static getI2CSCLPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (let portIndex = 0; portIndex < tBoard!.I2C_count; portIndex++)
    {
      for (const ePort of tBoard!.ports)
      {
        if (ePort[1] == ('SCL'+portIndex)
            || ePort[2] == ('SCL'+portIndex)
            || ePort[3] == ('SCL'+portIndex))
          ports.push(ePort[0]);
      }
    }

    return ports;
  }

  static getSPIMISOPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (let portIndex = 0; portIndex < tBoard!.SPI_count; portIndex++)
    {
      for (const ePort of tBoard!.ports)
      {
        if (ePort[1] == ('MISO'+portIndex)
            || ePort[2] == ('MISO'+portIndex)
            || ePort[3] == ('MISO'+portIndex))
          ports.push(ePort[0]);
      }
    }

    return ports;
  }

  static getSPIMOSIPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (let portIndex = 0; portIndex < tBoard!.SPI_count; portIndex++)
    {
      for (const ePort of tBoard!.ports)
      {
        if (ePort[1] == ('MOSI'+portIndex)
            || ePort[2] == ('MOSI'+portIndex)
            || ePort[3] == ('MOSI'+portIndex))
          ports.push(ePort[0]);
      }
    }

    return ports;
  }

  static getSPISCLPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (let portIndex = 0; portIndex < tBoard!.SPI_count; portIndex++)
    {
      for (const ePort of tBoard!.ports)
      {
        if (ePort[1] == ('SCL'+portIndex)
            || ePort[2] == ('SCL'+portIndex)
            || ePort[3] == ('SCL'+portIndex))
          ports.push(ePort[0]);
      }
    }

    return ports;
  }

  static getSPISSPortsfor(boardType:string):string[]{
    let ports:string[] = [];
    ports.length = 0;

    let tBoard = this.getBoard(boardType);

    for (let portIndex = 0; portIndex < tBoard!.SPI_count; portIndex++)
    {
      for (const ePort of tBoard!.ports)
      {
        if (ePort[1] == ('SS'+portIndex)
            || ePort[2] == ('SS'+portIndex)
            || ePort[3] == ('SS'+portIndex))
          ports.push(ePort[0]);
      }
    }

    return ports;
  }


  static getSuggestion(currentBoard:string, ports:[[string,string]], targetBoard:string):[[string,string]]{
    let suggestedPorts:[[string,string]]=[['','']];
    let reservePorts: string[] = [];

    let cBoard = this.getBoard(currentBoard);
    let tBoard = this.getBoard(targetBoard);

    reservePorts.length = 0;

    for (let index = 1; index < ports.length; index++)
    {
      suggestedPorts.push(['','']);
    }

    //matching priority:
    // 1: Serial Ports
    // 2: I2C Ports
    // 3: SPI Ports
    // 4: Interrupt Ports
    // 5: Analog Ports
    // 6: PWM Ports
    // 7: Digital Ports

    //1: Serial Ports
    let serialPortCount = 0;
    for (let index = 0; index < ports.length; index++)
    {
      if (ports[index][1] == 'RX' || ports[index][1] == 'TX')
      {
        let pFunc = this.getPortFunction(currentBoard, ports[index][0]);
        if (pFunc)
        {
          for (const efun of pFunc!)
          {
            if (efun.search(ports[index][1]) != -1)
              ports[index][1] = efun;
          }
        }
        serialPortCount++;
      }
    }

    serialPortCount /= 2;

    //looking for the match in the target board
    for (let index = 0; index < serialPortCount; index++)
    {
      for (let jindex = 0; jindex < ports.length; jindex++)
      {
        //if the port function is a receive serial
        if (ports[jindex][1].search('RX')!=-1)
        {
          for (const etports of tBoard!.ports)
          {
            if ((etports[1] == ('RX' + index)
                  || etports[2] == ('RX' + index)
                  || etports[3] == ('RX' + index))
                && !reserved(etports[0]))
            {
              suggestedPorts[jindex] = [ports[jindex][0], etports[0]];
              reservePorts.push(etports[0]);
            }
          }

          //search for corresponding TX ports and its suggestion
          for (let kindex = 0; kindex < ports.length; kindex++)
          {
            if (ports[kindex][1] == ('TX' + ports[jindex][1].replace('RX','')))
            {
              for (const etports of tBoard!.ports)
              {
                if ((etports[1] == ('TX' + index)
                      || etports[2] == ('TX' + index)
                      || etports[3] == ('TX' + index))
                    && !reserved(etports[0]))
                {
                  suggestedPorts[kindex] = [ports[kindex][0], etports[0]];
                }
              }
            }
          }
        }
      }
    }

    //2: I2C Ports
    let i2cPortCount = 0;
    for (let index = 0; index < ports.length; index++)
    {
      if (ports[index][1] == 'SDA' || ports[index][1] == 'SCL')
      {
        let pFunc = this.getPortFunction(currentBoard, ports[index][0]);
        if (pFunc)
        {
          for (const efun of pFunc!)
          {
            if (efun.search(ports[index][1]) != -1)
              ports[index][1] = efun;
          }
        }
        i2cPortCount++;
      }
    }

    i2cPortCount /= 2;

    //looking for the match in the target board
    for (let index = 0; index < i2cPortCount; index++)
    {
      for (let jindex = 0; jindex < ports.length; jindex++)
      {
        //if the port function is a receive serial
        if (ports[jindex][1].search('SDA')!=-1)
        {
          for (const etports of tBoard!.ports)
          {
            if ((etports[1] == ('SDA' + index)
                  || etports[2] == ('SDA' + index)
                  || etports[3] == ('SDA' + index))
                && !reserved(etports[0]))
            {
              suggestedPorts[jindex] = [ports[jindex][0], etports[0]];
              reservePorts.push(etports[0]);
            }
          }

          //search for corresponding TX ports and its suggestion
          for (let kindex = 0; kindex < ports.length; kindex++)
          {
            if (ports[kindex][1] == ('SCL' + ports[jindex][1].replace('SDA','')))
            {
              for (const etports of tBoard!.ports)
              {
                if ((etports[1] == ('SCL' + index)
                      || etports[2] == ('SCL' + index)
                      || etports[3] == ('SCL' + index))
                    && !reserved(etports[0]))
                {
                  suggestedPorts[kindex] = [ports[kindex][0], etports[0]];
                }
              }
            }
          }
        }
      }
    }

    //4: Interrupt Ports
    for (let index = 0; index < ports.length; index++)
    {
      if (ports[index][1] == 'INT')
      {
        for ( const etports of tBoard!.ports)
        {
          if ((etports[1] == 'INT' || etports[2] == 'INT' || etports[3] == 'INT')
              && !reserved(etports[0]))
          {
            suggestedPorts[index] = [ports[index][0], etports[0]];
            reservePorts.push(etports[0]);
            break;
          }
        }
      }
    }
    //5:Analog Ports
    for (let index = 0; index < ports.length; index++)
    {
      if (ports[index][1] == 'AI')
      {
        for ( const etports of tBoard!.ports)
        {
          if ((etports[1] == 'AI' || etports[2] == 'AI' || etports[3] == 'AI')
              && !reserved(etports[0]))
          {
            suggestedPorts[index] = [ports[index][0], etports[0]];
            reservePorts.push(etports[0]);
            break;
          }
        }
      }
    }
    //6:PWM Ports
    for (let index = 0; index < ports.length; index++)
    {
      if (ports[index][1] == 'PWM')
      {
        for ( const etports of tBoard!.ports)
        {
          if ((etports[1] == 'PWM' || etports[2] == 'PWM' || etports[3] == 'PWM')
              && !reserved(etports[0]))
          {
            suggestedPorts[index] = [ports[index][0], etports[0]];
            reservePorts.push(etports[0]);
            break;
          }
        }
      }
    }
    //7:Digital Ports
    for (let index = 0; index < ports.length; index++)
    {
      if (ports[index][1] == 'DIO')
      {
        for ( const etports of tBoard!.ports)
        {
          if ((etports[1] == 'DIO' || etports[2] == 'DIO' || etports[3] == 'DIO')
              && !reserved(etports[0]))
          {
            suggestedPorts[index] = [ports[index][0], etports[0]];
            reservePorts.push(etports[0]);
            break;
          }
        }
      }
    }

    function reserved(testPort:string):boolean{
      for (const aport of reservePorts)
      {
        if (testPort == aport)
        {
          return true;
        }
      }
      return false;
    }

    return suggestedPorts;
  }

};

BoardLibrary.Boards = [{
      'type'              : 'ARDUINO-UNO',
      'portCount'         : 20,
      'operatingVoltage'  : 5,
      'ports'             : [['D0','DIO','RX0','NOF'],
                            ['D1','DIO','TX0','NOF'],
                            ['D2','DIO','INT','NOF'],
                            ['D3','DIO','INT','PWM'],
                            ['D4','DIO','NOF','NOF'],
                            ['D5','DIO','PWM','NOF'],
                            ['D6','DIO','PWM','NOF'],
                            ['D7','DIO','NOF','NOF'],
                            ['D8','DIO','NOF','NOF'],
                            ['D9','DIO','PWM','NOF'],
                            ['D10','DIO','SS0','PWM'],
                            ['D11','DIO','MOSI0','PWM'],
                            ['D12','DIO','MISO0','NOF'],
                            ['D13','DIO','SCK0','NOF'],
                            ['A0','DIO','AI','NOF'],
                            ['A1','DIO','AI','NOF'],
                            ['A2','DIO','AI','NOF'],
                            ['A3','DIO','AI','NOF'],
                            ['A4','DIO','AI','SDA0'],
                            ['A5','DIO','AI','SCL0']
                            ],
      'availPorts'        : ['D0','D1','D2','D3','D4','D5','D6','D7','D8','D9','D10','D11','D12','D13','A0','A1','A2','A3','A4','A5'],
      'I2C_count'         : 1,
      'SPI_count'         : 1,
      'Serial_count'      : 1,
      'built_in_led'      : '13',
      'compilerFlag'      : 'arduino:avr:uno',
      'serialSpeed'       : ['300','600','1200','2400','4800','9600','14400',
                             '19200','28800','31250','38400','57600','115200']
  },
  {
    'type'              : 'ARDUINO-MEGA',
    'portCount'         : 70,
    'operatingVoltage'  : 5,
    'ports'             : [['D0','DIO','RX0','NOF'],
                          ['D1','DIO','TX0','NOF'],
                          ['D2','DIO','INT','PWM'],
                          ['D3','DIO','INT','PWM'],
                          ['D4','DIO','PWM','NOF'],
                          ['D5','DIO','PWM','NOF'],
                          ['D6','DIO','PWM','NOF'],
                          ['D7','DIO','PWM','NOF'],
                          ['D8','DIO','PWM','NOF'],
                          ['D9','DIO','PWM','NOF'],
                          ['D10','DIO','PWM','NOF'],
                          ['D11','DIO','PWM','NOF'],
                          ['D12','DIO','PWM','NOF'],
                          ['D13','DIO','PWM','NOF'],
                          ['D14','DIO','TX3','NOF'],
                          ['D15','DIO','RX3','NOF'],
                          ['D16','DIO','TX2','NOF'],
                          ['D17','DIO','RX2','NOF'],
                          ['D18','DIO','TX1','INT'],
                          ['D19','DIO','RX1','INT'],
                          ['D20','DIO','SDA0','INT'],
                          ['D21','DIO','SCL0','INT'],
                          ['D22','DIO','NOF','NOF'],
                          ['D23','DIO','NOF','NOF'],
                          ['D24','DIO','NOF','NOF'],
                          ['D25','DIO','NOF','NOF'],
                          ['D26','DIO','NOF','NOF'],
                          ['D27','DIO','NOF','NOF'],
                          ['D28','DIO','NOF','NOF'],
                          ['D29','DIO','NOF','NOF'],
                          ['D30','DIO','NOF','NOF'],
                          ['D31','DIO','SDA1','NOF'],
                          ['D32','DIO','SCL1','NOF'],
                          ['D33','DIO','NOF','NOF'],
                          ['D34','DIO','NOF','NOF'],
                          ['D35','DIO','NOF','NOF'],
                          ['D36','DIO','NOF','NOF'],
                          ['D37','DIO','NOF','NOF'],
                          ['D38','DIO','NOF','NOF'],
                          ['D39','DIO','NOF','NOF'],
                          ['D40','DIO','NOF','NOF'],
                          ['D41','DIO','NOF','NOF'],
                          ['D42','DIO','NOF','NOF'],
                          ['D43','DIO','NOF','NOF'],
                          ['D44','DIO','PWM','NOF'],
                          ['D45','DIO','PWM','NOF'],
                          ['D46','DIO','PWM','NOF'],
                          ['D47','DIO','NOF','NOF'],
                          ['D48','DIO','NOF','NOF'],
                          ['D49','DIO','NOF','NOF'],
                          ['D50','DIO','MISO0','NOF'],
                          ['D51','DIO','MOSI0','NOF'],
                          ['D52','DIO','SCK0','NOF'],
                          ['D53','DIO','SS0','NOF'],
                          ['A0','DIO','AI','NOF'],
                          ['A1','DIO','AI','NOF'],
                          ['A2','DIO','AI','NOF'],
                          ['A3','DIO','AI','NOF'],
                          ['A4','DIO','AI','NOF'],
                          ['A5','DIO','AI','NOF'],
                          ['A6','DIO','AI','NOF'],
                          ['A7','DIO','AI','NOF'],
                          ['A8','DIO','AI','NOF'],
                          ['A9','DIO','AI','NOF'],
                          ['A10','DIO','AI','NOF'],
                          ['A11','DIO','AI','NOF'],
                          ['A12','DIO','AI','NOF'],
                          ['A13','DIO','AI','NOF'],
                          ['A14','DIO','AI','NOF'],
                          ['A15','DIO','AI','NOF']
                          ],
    'availPorts'        : ['D0','D1','D2','D3','D4','D5','D6','D7','D8','D9','D10',
                           'D11','D12','D13','D14','D15','D16','D17','D18','D19','D20','D21',
                           'D22','D23','D24','D25','D26','D27','D28','D29','D30','D31','D32','D33',
                           'D34','D35','D36','D37','D38','D39','D40','D41','D42','D43','D44','D45',
                           'D46','D47','D48','D49','D50','D51','D52','D53',
                           'A0','A1','A2','A3','A4','A5','A6','A7','A8','A9','A10','A11','A12','A13','A14','A15'],
    'I2C_count'         : 2,
    'SPI_count'         : 1,
    'Serial_count'      : 4,
    'built_in_led'      : '13',
    'compilerFlag'      : 'arduino:avr:mega',
    'serialSpeed'       : ['300','600','1200','2400','4800','9600','14400',
                           '19200','28800','31250','38400','57600','115200']
  }
]

