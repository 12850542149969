import * as goog from '../closure/goog/goog.js';
goog.declareModuleId('Blockly.Hardware');

import {NameType} from './names.js';
import {arduinoGenerator as Arduino} from '../generators/arduino.js';
import {Workspace} from './workspace.js';
import { HardwareModuleLibrary } from './hardware_module_library.js';
import { Block } from './block.js';
import { BoardLibrary } from './board_library.js';
import { Board } from './board_library.js';

export class Hardware {
  static currentBoard: string;
  static usedModules : [string,string,string,string[]][] = [];
  //usedModules_ : ModuleType, ModuleName, ModuleId, Ports
  static availableDigitalPorts : [string,string][] = [['nil','nil']];
  static availablePWMPorts : [string,string][] = [['nil','nil']];
  static availableAnalogPorts : [string,string][] = [['nil','nil']];
  static availabeInterruptPorts : [string,string][] = [['nil','nil']];
  static availableI2CPorts : [string,string][] = [['nil','nil']];
  //[[SDA0,SCL0],[SDA1,SCL1]]
  static availableSerialPorts : [string,string][] = [['nil','nil']];
  //[[RX0,TX0],[RX1,TX1],[RX2,TX2]]
  static availableSPIPorts : [string,string,string,string][] = [['nil','nil','nil','nil']];
  //[[MOSI0,MISO0,SCK0,SS0],[MOSI1,MISO1,SCK1,SS1]]
  static allPortsOnBoard : string[] = ['nil'];

  static usedPorts : [string,string][] = [];
  //usedPorts : portName, function
  static builtInLED : [string,string][] = [['13','13']];

  static includes_ : [string,string][] = [];
  static declarations_ : [string,string][] = [];
  static setups_ : [string,string][] = [];
  static variables_ : [string,string][] = [];

  static flyoutBlocks : [string,string][] = [];
  //blocktype, ModuleName

  static busPort = [['SDA','I2C'],[ 'SCL','I2C'],
                    ['RX','SERIAL'],['TX','SERIAL'],
                    ['MOSI','SPI'],['MISO','SPI'],['SCK','SPI'],['SS','SPI']];

  static updateBlockInfo( xml : Element ){
    this.includes_.length = 0;
    this.declarations_.length = 0;
    this.setups_.length = 0;
    this.variables_.length = 0;
    this.usedPorts.length = 0;

    this.currentBoard = xml.children[0].getAttribute("type")!;
    let hBoard = BoardLibrary.getBoard(this.currentBoard!);

    //initialize the arrays
    this.usedModules.length = 0;
    //decode xml and calcalate the arrays and coding needed
    let moduleElement = xml.getElementsByTagName("module");
    for (const everyModule of moduleElement)
    {
      let moduletype = everyModule.getAttribute("type");
      let modulename = everyModule.getAttribute("name");
      let moduleid = everyModule.getAttribute("id");
      let moduleports = everyModule.parentElement?.getAttribute("portnames");

      //update usedModules_
      //this array is used to record all the modules used in this workspace
      let mPorts = moduleports?.split(" ");

      this.usedModules.push([moduletype!, modulename!, moduleid!, mPorts!]);

      let Module = HardwareModuleLibrary.Mod.find(({type})=> type === moduletype);
      
      //update usedPorts
      let i = 0;
      for (const eport of mPorts!)
      {
        this.usedPorts.push([eport, Module!.ports[i][1]]);
      }
      
      //update initialization
      if (Module != undefined)
      {
        if (Module?.includeText != '')
        {
          for (let i=0; i< this.includes_.length; i++)
          {
            if (this.includes_[i][0] == moduletype)
            {
              break;
            }
          }
          this.includes_.push([moduletype!, 
               HardwareModuleLibrary.getModuleIncludeCode(moduletype!)]);
        }

        if (Module.declarationText != '')
        {
          for (let i=0; i<this.declarations_.length;i++)
          {
            if (this.declarations_[i][0] == moduleid)
              break;
          }
          this.declarations_.push([moduleid!, 
               HardwareModuleLibrary.getModuleDeclarationCode(moduletype!, moduleid!)]);
        }

        if (Module.setupText != '')
        {
          for (let i=0; i<this.setups_.length; i++)
          {
            if (this.setups_[i][0] == moduleid)
              break;
          }
          this.setups_.push([moduleid!, 
               HardwareModuleLibrary.getModuleSetupCode(moduletype!,moduleid!)]);
        }

        //still need to add for variables
      }
        
  
    }

    //making a list of blocks for every single used modules to be used int
    //dynamic flyout category
    //clear the flyoutBlocks array first.
    this.flyoutBlocks.length = 0;
    for (const eMod of this.usedModules)
    {
      let Module = HardwareModuleLibrary.Mod.find(({type})=> type === eMod[0]);
      //for every block in toolboxBlocks, add them into the flyout array
      for (const eBlock of Module?.toolboxBlocks!)
      {
        this.flyoutBlocks.push([eBlock, eMod[1]]);
      }
    }

    /*******************************************************
     * Analysing available ports according to function
     *******************************************************/
    if (hBoard)
    {
      this.builtInLED = [[hBoard?.built_in_led!,hBoard?.built_in_led!]];
      this.allPortsOnBoard = hBoard?.availPorts!;
      this.availabeInterruptPorts.length = 0;
      this.availableAnalogPorts.length = 0;
      this.availableDigitalPorts.length = 0;
      this.availablePWMPorts.length = 0;
      this.availableI2CPorts.length = 0;
      this.availableSPIPorts.length = 0;
      this.availableSerialPorts.length = 0;

      for (const ePort of hBoard!.ports)
      {
        if ((ePort[1] == 'DIO' || ePort[2] == 'DIO' || ePort[3] == 'DIO')
            && !this.isUsedPort(ePort[0]))
          this.availableDigitalPorts.push([ePort[0],ePort[0]]);
        if ((ePort[1] == 'PWM' || ePort[2] == 'PWM' || ePort[3] == 'PWM')
            && !this.isUsedPort(ePort[0]))
          this.availablePWMPorts.push([ePort[0],ePort[0]]);
        if ((ePort[1] == 'AI' || ePort[2] == 'AI' || ePort[3] == 'AI')
            && !this.isUsedPort(ePort[0]))
          this.availableAnalogPorts.push([ePort[0],ePort[0]]);
        if ((ePort[1] == 'INT' || ePort[2] == 'INT' || ePort[3] == 'INT')
            && !this.isUsedPort(ePort[0]))
          this.availabeInterruptPorts.push([ePort[0],ePort[0]]);
      }

      for (let i=0; i<hBoard.I2C_count; i++)
      {
        this.availableI2CPorts.push(['','']);
      }
      //for I2C ports
      for (let i=0; i<hBoard.I2C_count; i++)
      {
        for (const ePort of hBoard!.ports)
        {
          if (((ePort[1].search('SDA'+i) != -1)
               ||(ePort[2].search('SDA'+i) != -1)
               ||(ePort[3].search('SDA'+i) != -1))
               && ((this.isUsedPort(ePort[0])) == 2 || !this.isUsedPort(ePort[0])))
          {
            this.availableI2CPorts[i][0] = ePort[0];
          }
          if (((ePort[1].search('SCL'+i) != -1)
               ||(ePort[2].search('SCL'+i) != -1)
               ||(ePort[3].search('SCL'+i) != -1))
               && ((this.isUsedPort(ePort[0])) == 2 || !this.isUsedPort(ePort[0])))
          {
            this.availableI2CPorts[i][1] = ePort[0];
          }
        }
      }

      for (let i=0; i<hBoard.I2C_count; i++)
      {
        if (this.availableI2CPorts[i][0] == '' || this.availableI2CPorts[i][1] == '')
        {
          this.availableI2CPorts[i][0] = '';
          this.availableI2CPorts[i][1] = '';
        }
      }

      //for Serial ports
      for (let i=0; i<hBoard.Serial_count; i++)
      {
        this.availableSerialPorts.push(['','']);
      }

      for (let i=0; i<hBoard.Serial_count; i++)
      {
        for (const ePort of hBoard!.ports)
        {
          if (((ePort[1].search('RX'+i) != -1)
               ||(ePort[2].search('RX'+i) != -1)
               ||(ePort[3].search('RX'+i) != -1))
               && ((this.isUsedPort(ePort[0])) == 3 || !this.isUsedPort(ePort[0])))
          {
            this.availableSerialPorts[i][0] = ePort[0];
          }
          if (((ePort[1].search('TX'+i) != -1)
               ||(ePort[2].search('TX'+i) != -1)
               ||(ePort[3].search('TX'+i) != -1))
               && ((this.isUsedPort(ePort[0])) == 3 || !this.isUsedPort(ePort[0])))
          {
            this.availableSerialPorts[i][1] = ePort[0];
          }
        }
      }

      for (let i=0; i<hBoard.Serial_count; i++)
      {
        if (this.availableSerialPorts[i][0] == '' || this.availableSerialPorts[i][1] == '')
        {
          this.availableSerialPorts[i][0] = '';
          this.availableSerialPorts[i][1] = '';
        }
      }

      //for SPI ports
      for (let i=0; i<hBoard.SPI_count; i++)
      {
        this.availableSPIPorts.push(['','','','']);
      }

      for (let i=0; i<hBoard.SPI_count; i++)
      {
        for (const ePort of hBoard!.ports)
        {
          if (((ePort[1].search('MOSI'+i) != -1)
               ||(ePort[2].search('MOSI'+i) != -1)
               ||(ePort[3].search('MOSI'+i) != -1))
               && ((this.isUsedPort(ePort[0])) == 4 || !this.isUsedPort(ePort[0])))
          {
            this.availableSPIPorts[i][0] = ePort[0];
          }
          if (((ePort[1].search('MISO'+i) != -1)
               ||(ePort[2].search('MISO'+i) != -1)
               ||(ePort[3].search('MISO'+i) != -1))
               && ((this.isUsedPort(ePort[0])) == 4 || !this.isUsedPort(ePort[0])))
          {
            this.availableSPIPorts[i][1] = ePort[0];
          }
          if (((ePort[1].search('SCK'+i) != -1)
               ||(ePort[2].search('SCK'+i) != -1)
               ||(ePort[3].search('SCK'+i) != -1))
               && ((this.isUsedPort(ePort[0])) == 4 || !this.isUsedPort(ePort[0])))
          {
            this.availableSPIPorts[i][2] = ePort[0];
          }
          if (((ePort[1].search('SS'+i) != -1)
               ||(ePort[2].search('SS'+i) != -1)
               ||(ePort[3].search('SS'+i) != -1))
               && ((this.isUsedPort(ePort[0])) == 4 || !this.isUsedPort(ePort[0])))
          {
            this.availableSPIPorts[i][3] = ePort[0];
          }
        }
      }

      for (let i=0; i<hBoard.SPI_count; i++)
      {
        if (this.availableSPIPorts[i][0] == '' || this.availableSPIPorts[i][1] == ''
            || this.availableSPIPorts[i][2] == '' || this.availableSPIPorts[i][3] == '')
        {
          this.availableSPIPorts[i][0] = '';
          this.availableSPIPorts[i][1] = '';
          this.availableSPIPorts[i][2] = '';
          this.availableSPIPorts[i][3] = '';
        }
      }

    }

    
  };

  static deleteModuleUpdateWorkspace(modType:string, modName:string, ws:Workspace){

    let mod = HardwareModuleLibrary.Mod.find(({type})=>type===modType);

    let selectedBlocks : Block[] = [];
    selectedBlocks.length = 0;

    for (const eBlockType of mod?.toolboxBlocks!)
    {
      selectedBlocks = selectedBlocks.concat(ws.getBlocksByType(eBlockType, false));
    }
    
    let toDeleteBlocksId:string[] = [];
    toDeleteBlocksId.length = 0;
    for (const eBlock of selectedBlocks)
    {
      if (eBlock.getFieldValue("ModuleName") == modName)
      {
        toDeleteBlocksId.push(eBlock.id);
      }
    }

    for (const eId of toDeleteBlocksId)
    {
      ws.getBlockById(eId)?.dispose(true);
    }

  };

  static isUsedPort(port:string):number{
    for (const everyPort of this.usedPorts)
    {
      if (everyPort[0] == port)
      {
        if (everyPort[1] == 'DIO' ||
            everyPort[1] == 'AI' ||
            everyPort[1] == 'PWM')
          return 1;
        else if (everyPort[1].search('SDA') != -1 ||
                 everyPort[1].search('SCL') != -1)
          return 2;
        else if (everyPort[1].search('RX') != -1 ||
                 everyPort[1].search('TX') != -1)
          return 3;
        else if (everyPort[1].search('MOSI') != -1 ||
                 everyPort[1].search('MISO') != -1 ||
                 everyPort[1].search('SCK') != -1 ||
                 everyPort[1].search('SS') != -1)
          return 4;
      }
    }
    return 0;

    //return 0 if the port is not in use
    //return 1 if the port is in use but for generic i/o purpose
    //return 2 if the port is in use for i2c
    //return 3 if the port is in use for serial communication rxd,txd
    //return 4 if the port is used for spi interface

  };

  static checkModulePort(mod:string):string{
    //check type in mod against all available modules

    let thisModule = HardwareModuleLibrary.getModule(mod);
    let returnType = '';
    for (const everyPort of thisModule!.ports)
    {
      for (const anyType of this.busPort)
      {
        if (everyPort[0] == anyType[0])
        {
          if (returnType == '')
            returnType = anyType[1];
          else if (returnType != anyType[1])
            return 'ERROR';
        }
      }
    }
    if (returnType == '')
      return 'GPIO';
    else return returnType;
  };

  static getAvailableI2CPorts():[string,string][]{
    let tempPorts:[string,string][] = [];
    
    for (const ePort of this.availableI2CPorts)
    {
      if ( ePort[0] != '' && ePort[1] != '')
        tempPorts.push(ePort);
    }
    return tempPorts;
  };

  static getAvailableSerialPorts():[string,string][]{
    let tempPorts:[string,string][] = [];

    for (const ePort of this.availableSerialPorts)
    {
      if ( ePort[0] != '' && ePort[1] != '' )
        tempPorts.push(ePort);
    }
    return tempPorts;
  };

  static getAvailableSPIPorts():[string,string,string,string][]{
    let tempPorts:[string,string,string,string][] = [];

    for (const ePort of this.availableSPIPorts)
    {
      if ( ePort[0] != '' && ePort[1] != ''
           && ePort[2] != '' && ePort[3] != '')
        tempPorts.push(ePort);
    }
    return tempPorts;
  }

}