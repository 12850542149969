/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import * as goog from '../closure/goog/goog.js';
goog.declareModuleId('Blockly.ModulesDynamic');

import {Blocks} from './blocks.js';
import {Msg} from './msg.js';
import * as xml from './utils/xml.js';
import {VariableModel} from './variable_model.js';
import * as Variables from './variables.js';
import type {Workspace} from './workspace.js';
import type {WorkspaceSvg} from './workspace_svg.js';
import type {FlyoutButton} from './flyout_button.js';
import { Hardware } from './hardware.js';

/**
 * String for use in the "custom" attribute of a category in toolbox XML.
 * This string indicates that the category should be dynamically populated with
 * variable blocks.
 * See also Blockly.Variables.CATEGORY_NAME and
 * Blockly.Procedures.CATEGORY_NAME.
 */
export const CATEGORY_NAME = 'MODULES_DYNAMIC';


/**
 * Construct the elements (blocks and button) required by the flyout for the
 * variable category.
 *
 * @param workspace The workspace containing variables.
 * @returns Array of XML elements.
 */
export function flyoutCategory(workspace: WorkspaceSvg): Element[] {
  let xmlList = new Array<Element>();

  const blockList = flyoutCategoryBlocks(workspace);
  xmlList = xmlList.concat(blockList);
  return xmlList;
}

/**
 * Construct the blocks required by the flyout for the variable category.
 *
 * @param workspace The workspace containing variables.
 * @returns Array of XML block elements.
 */
export function flyoutCategoryBlocks(workspace: Workspace): Element[] {

  const xmlList : Element[] = [];
  let blockElement = document.createElement("block");
  let blockField = document.createElement("field");
  let blockNode = document.createTextNode("4");
  blockElement.setAttribute('type','math_number');
  blockField.setAttribute('name', 'NUM');
  blockField.appendChild(blockNode);
  blockElement.appendChild(blockField);
  xmlList.push(blockElement);

  blockElement = document.createElement("block");
  blockField = document.createElement("field");
  blockNode = document.createTextNode("15");
  blockElement.setAttribute('type','math_number');
  blockField.setAttribute('name', 'NUM');
  blockField.appendChild(blockNode);
  blockElement.appendChild(blockField);
  xmlList.push(blockElement);

  for (const eBlock of Hardware.flyoutBlocks)
  {
    blockElement = document.createElement("block");
    blockElement.setAttribute('type', eBlock[0]);
    blockField = document.createElement("field");
    blockField.setAttribute('name', 'ModuleName');
    blockNode = document.createTextNode(eBlock[1]);
    blockField.appendChild(blockNode);
    blockElement.appendChild(blockField);
    xmlList.push(blockElement);
  }

  console.log(xmlList);
  
  return xmlList;
}
