/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import * as goog from '../closure/goog/goog.js';
goog.declareModuleId('Blockly.VariablesDynamic');

import {Blocks} from './blocks.js';
import {Msg} from './msg.js';
import * as xml from './utils/xml.js';
import {VariableModel} from './variable_model.js';
import * as Variables from './variables.js';
import type {Workspace} from './workspace.js';
import type {WorkspaceSvg} from './workspace_svg.js';
import type {FlyoutButton} from './flyout_button.js';

/**
 * String for use in the "custom" attribute of a category in toolbox XML.
 * This string indicates that the category should be dynamically populated with
 * variable blocks.
 * See also Blockly.Variables.CATEGORY_NAME and
 * Blockly.Procedures.CATEGORY_NAME.
 */
export const CATEGORY_NAME = 'VARIABLE_DYNAMIC';

/**
 * Click handler for a button that creates String variables.
 *
 * @param button
 */
function stringButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'charArray'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_String = stringButtonClickHandler;

/**
 * Click handler for a button that creates Number variables.
 *
 * @param button
 */
function integerButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'int'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_Integer = integerButtonClickHandler;

/**
 * Click handler for a button that creates Colour variables.
 *
 * @param button
 */
function uIntegerButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'unsigned int'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_Un_Integer = uIntegerButtonClickHandler;

/**
 * Click handler for a button that creates Colour variables.
 *
 * @param button
 */
function longButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'long'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_Long = longButtonClickHandler;

/**
 * Click handler for a button that creates Colour variables.
 *
 * @param button
 */
function uLongButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'unsigned long'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_ULong = uLongButtonClickHandler;

/**
 * Click handler for a button that creates Colour variables.
 *
 * @param button
 */
function floatButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'float'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_Float = floatButtonClickHandler;

/**
 * Click handler for a button that creates Colour variables.
 *
 * @param button
 */
function doubleButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'double'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_Double = doubleButtonClickHandler;

/**
 * Click handler for a button that creates Colour variables.
 *
 * @param button
 */
function byteButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'byte'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_Byte = byteButtonClickHandler;

/**
 * Click handler for a button that creates Colour variables.
 *
 * @param button
 */
function charButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'char'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_Char = charButtonClickHandler;

/**
 * Click handler for a button that creates Colour variables.
 *
 * @param button
 */
function wordButtonClickHandler(button: FlyoutButton) {
  Variables.createVariableButtonHandler(
    button.getTargetWorkspace(),
    undefined,
    'word'
  );
}
// eslint-disable-next-line camelcase
export const onCreateVariableButtonClick_Word = wordButtonClickHandler;

/**
 * Construct the elements (blocks and button) required by the flyout for the
 * variable category.
 *
 * @param workspace The workspace containing variables.
 * @returns Array of XML elements.
 */
export function flyoutCategory(workspace: WorkspaceSvg): Element[] {
  let xmlList = new Array<Element>();
  let button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_STRING_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_STRING');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_INT_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_INT');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_UINT_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_UINT');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_LONG_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_LONG');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_ULONG_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_ULONG');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_FLOAT_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_FLOAT');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_DOUBLE_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_DOUBLE');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_BYTE_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_BYTE');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_CHAR_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_CHAR');
  xmlList.push(button);
  button = document.createElement('button');
  button.setAttribute('text', Msg['NEW_WORD_VARIABLE']);
  button.setAttribute('callbackKey', 'CREATE_VARIABLE_WORD');
  xmlList.push(button);


  workspace.registerButtonCallback(
    'CREATE_VARIABLE_STRING',
    stringButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_INT',
    integerButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_UINT',
    uIntegerButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_LONG',
    longButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_ULONG',
    uLongButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_FLOAT',
    floatButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_DOUBLE',
    doubleButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_BYTE',
    byteButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_CHAR',
    charButtonClickHandler
  );
  workspace.registerButtonCallback(
    'CREATE_VARIABLE_WORD',
    wordButtonClickHandler
  );

  const blockList = flyoutCategoryBlocks(workspace);
  xmlList = xmlList.concat(blockList);
  return xmlList;
}

/**
 * Construct the blocks required by the flyout for the variable category.
 *
 * @param workspace The workspace containing variables.
 * @returns Array of XML block elements.
 */
export function flyoutCategoryBlocks(workspace: Workspace): Element[] {
  const variableModelList = workspace.getAllVariables();

  const xmlList : Element[] = [];
  if (variableModelList.length > 0) {
    if (Blocks['variables_set_dynamic']) {
      const firstVariable = variableModelList[variableModelList.length - 1];
      const block = xml.createElement('block');
      block.setAttribute('type', 'variables_set_dynamic');
      block.setAttribute('gap', '24');
      block.appendChild(Variables.generateVariableFieldDom(firstVariable));
      xmlList.push(block);
    }
    if (Blocks['variables_get_dynamic']) {
      variableModelList.sort(VariableModel.compareByName);
      for (let i = 0, variable; (variable = variableModelList[i]); i++) {
        const block = xml.createElement('block');
        block.setAttribute('type', 'variables_get_dynamic');
        block.setAttribute('gap', '8');
        block.appendChild(Variables.generateVariableFieldDom(variable));
        xmlList.push(block);
      }
    }
  }
  return xmlList;
}
