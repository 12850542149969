import * as goog from '../closure/goog/goog.js';
goog.declareModuleId('Blockly.HardwareModuleLibrary');

export interface Modules {
  type : string,
  name : string,
  connectSide : string,
  id : string,
  portCount : number,
  ports:string[][],
  operatingVoltage: number,
  includeText:string,
  declarationText:string,
  setupText:string,
  variableText: string[],
  toolboxBlocks:string[]
}

export class HardwareModuleLibrary{
  static Mod :Modules[] = [];

  static getModuleIncludeCode(modType:string):string{
    for (const everyMod of HardwareModuleLibrary.Mod)
    {
      if (everyMod.type === modType)
        return everyMod.includeText;
    }
    return '';
  };

  static getModuleDeclarationCode(modType:string, modId:string):string{
    let internalID = modId;
    for (const everyMod of HardwareModuleLibrary.Mod)
    {
      if (everyMod.type === modType)
      {
        return everyMod.declarationText.replace('%ID', internalID);
      }
    }
    return '';
  };

  static getModuleSetupCode(modType:string, modId:string):string{
    let internalID = modId;
    for (const everyMod of HardwareModuleLibrary.Mod)
    {
      if (everyMod.type === modType)
      {
        return everyMod.setupText.replace('%ID', internalID);
      }
    }
    return '';
  };

  static getModuleVariables(modType:string):string[]{
    for (const everyMod of HardwareModuleLibrary.Mod)
    {
      if (everyMod.type === modType)
        return everyMod.variableText;
    }
    return [];
  }

  static getModule(modType:string):Modules|undefined{

    for (const eMod of this.Mod)
    {
      if (eMod.type == modType)
        return eMod;
    }

    return undefined;
  }
}


HardwareModuleLibrary.Mod = [
{
  'type' : 'GEN_DIN',
  'name' : 'DIN',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount':1,
  'ports':[['DO','DIO']],
  'operatingVoltage': 5,
  'includeText' : '',
  'declarationText' : '',
  'setupText' : '',
  'variableText' : [],
  'toolboxBlocks':['g_io_digitalread']
},
{
  'type' : 'GEN_DOUT',
  'name' : 'DOUT',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount':1,
  'ports':[['DI','DIO']],
  'operatingVoltage': 5,
  'includeText' : '',
  'declarationText' : '',
  'setupText' : '',
  'variableText' : [],
  'toolboxBlocks':['g_io_digitalwrite']
},
{
  'type' : 'GEN_AIN',
  'name' : 'AN_IN',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount':1,
  'ports':[['AO','AI']],
  'operatingVoltage': 5,
  'includeText' : '',
  'declarationText' : '',
  'setupText' : '',
  'variableText' : [],
  'toolboxBlocks':['g_io_analogread']
},
{
  'type' : 'GEN_AOUT',
  'name' : 'AN_OUT',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount':1,
  'ports':[['AI','PWM']],
  'operatingVoltage': 5,
  'includeText' : '',
  'declarationText' : '',
  'setupText' : '',
  'variableText' : [],
  'toolboxBlocks':['g_io_analogwrite']
},
{
  'type' : 'GEN_PULSEIN',
  'name' : 'D_PULSE',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount':1,
  'ports':[['DI','DIO']],
  'operatingVoltage': 5,
  'includeText' : '',
  'declarationText' : '',
  'setupText' : '',
  'variableText' : [],
  'toolboxBlocks':['g_io_pulsein']
},
{
  'type' : 'GEN_PULSETIMEOUT',
  'name' : 'PULSE_TO',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount':1,
  'ports':[['DI','DIO']],
  'operatingVoltage': 5,
  'includeText' : '',
  'declarationText' : '',
  'setupText' : '',
  'variableText' : [],
  'toolboxBlocks':['g_io_pulsetimeout']
},
{
  'type' : 'DHT22',
  'name' : 'DHT',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount':1,
  'ports':[['DATA','DIO']],
  'operatingVoltage': 5,
  'includeText' : '#include <DHT22.h>',
  'declarationText' : 'DHT22 %ID(SDA);',
  'setupText' : '',
  'variableText' : [],
  'toolboxBlocks':['dht22-get-temperature', 'dht22-get-humidity']
},
{
  'type' : 'LCD20-I2C',
  'name' : 'LCD',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount': 2,
  'ports':[['SDA','SDA'],['SCL','SCL']],
  'operatingVoltage' : 5,
  'includeText':'#include <Wire.h>\n#include <LiquidCrystal_I2C.h>',
  'declarationText':'LiquidCrystal_I2C %ID(0x27,20,4);',
  'setupText':'%ID.init();',
  'variableText': [],
  'toolboxBlocks':['lcd-display-on-line']
},
{
  'type' : 'SOIL-MOISTURE',
  'name' : 'SOIL_MOIST',
  'connectSide' : 'none',
  'id' : 'none',
  'portCount' : 1,
  'ports' : [['AO','AI']],
  'operatingVoltage' : 5,
  'includeText':'',
  'declarationText':'',
  'setupText':'',
  'variableText': [],
  'toolboxBlocks':['soil-moisture']
}];
